import type { CustomOptionsProps } from '../../types';

export const customOptions: CustomOptionsProps = {
  languageCode: 'it',
  regionISO: 'US',
  cookieManagementEnabled: true,
  googleAdsEnabled: false,
  headerLiftEnabled: true,
  googleSearchConsoleId: '',
  googleTagManagerCode: '',
  googleAnalyticsId: '',
  facebookTrackingId: '',
  twitterHandle: '@gamedistribution',
  twitterSiteHandle: '@azerion',
  appIconId: '2b395754-da2f-4518-9fd8-facb575ef4e7',
  headerLogoId: '5b9f682a-aef1-46ca-a7d7-a8b8ef752704',
  footerLogoId: 'fb167528-440e-4302-9c2c-6962bb6d0107',
  fontUrl: 'https://fonts.googleapis.com/css2?family=Mulish&display=swap',
  defaultTitle: 'Money',
  titleTemplate: 'or-site-money | %s',
  noFollow: true,
  noIndex: true,
  cookieLawProId: '',
  extraHeaderURL: '',
  extraFooterURL: '',
  extraHeaderStyleURL: '',
  extraFooterStyleURL: '',
  customCss: ``,
  scripts: [
    {
      id: 'idhb', // Required by HeaderLift.
      src: 'https://hb.improvedigital.com/pbw/headerlift.min.js',
      strategy: 'afterInteractive'
    },
  ],
  links: [
  ],
};